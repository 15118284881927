<template>
  <div class="modal workout_modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Workouts <span>Add upto 3 Workouts</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body pt-0">
                <div class="setting_wpr workout_list">
                    <draggable v-model="selectedWorkouts" item-key="id" tag="ul" :animation="200" class="workout_card" v-if="selectedWorkouts.length">
                        <template #item="{ element : selectedWorkout }">
                            <li>
                                <div class="wk_item">
                                    <img v-if="selectedWorkout.thumb" :src="selectedWorkout.thumb">
                                    <img v-else src="@/assets/images/thumb/default-progress.svg">
                                    <div class="card_details">
                                        {{selectedWorkout.name}}
                                    </div>
                                    <span class="delete act_btn" @click="selectedWorkouts.splice(index, 1)"><i class="fas fa-minus"></i></span>
                                </div>
                                <div class="period">
                                    <div class="form_grp p-0 m-0 w-100">
                                        <div class="group_item">
                                            <multiselect v-model="selectedWorkout.period" v-bind="period"></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </draggable>
                    <div class="empty_area" v-else>
                        <h4>Looks like you don't have any Workouts yet.</h4>
                    </div>
                </div>
                <div class="setting_wpr">
                    <h3 class="sub_header mb-3">Workout List</h3>
                    <draggable v-model="workoutList" tag="ul" item-key="id" :animation="200" class="workout_card" v-if="workoutList && workoutList.length" :disabled="true">
                        <template #item="{ element : workout}">
                            <li>
                                <div class="wk_item">
                                    <img v-if="workout.thumb" :src="workout.thumb">
                                    <img v-else src="@/assets/images/thumb/default-progress.svg">
                                    <div class="card_details">
                                        {{workout.name}}
                                    </div>
                                    <span class="act_btn" @click="handleAddWorkout(workout)"><i class="fas fa-plus"></i></span>
                                </div>
                                <div class="period">
                                    <div class="form_grp p-0 m-0 w-100">
                                        <div class="group_item">
                                            <multiselect v-model="workout.period" v-bind="period"></multiselect>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </draggable>
                    <div class="empty_area" v-else>
                        <h4>Looks like you don't have any Workouts yet.</h4>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSavePlanWeekWorkout()"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import Draggable from 'vuedraggable'

    export default {
        name: 'Edit Plan Workouts',

        data () {
            return {
                workoutList: [],
                period: {
                    mode: 'single',
                    value: 'AM',
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'Mid', label: 'Mid' },
                        { value: 'PM', label: 'PM' }
                    ],
                },
                selectedWorkouts: [],
            }
        },

        props: {
            modelValue: Boolean,
            selectedWeekDay: {
                type: Object,
                default: () => {}
            }
        },

        emit: ['update:modelValue'],

        components: {
            Draggable
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleScrollBar(value);

                if (value) {
                    if (!Object.keys(vm.allWorkouts).length) {
                        vm.workoutList = [];
                        vm.getAllWorkouts({});
                    } else {
                        vm.workoutList = JSON.parse(JSON.stringify(vm.allWorkouts));
                    }

                    vm.handleSelectedWorkout();
                }
            },

            allWorkouts (workouts) {
                const vm = this;

                if (workouts && workouts.length) {
                    vm.workoutList = JSON.parse(JSON.stringify(workouts));
                }
            },
        },

        computed: mapState({
            allWorkouts: (state) => state.workoutModule.allWorkouts,
            allWorkoutLoader: (state) => state.workoutModule.allWorkoutLoader,
            loader: (state) => state.workoutModule.savePlanWeekLoader,
        }),

        methods: {
            ...mapActions({
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                savePlanWeekWorkout: 'workoutModule/savePlanWeekWorkout',
                getWorkoutPlanWeeks: 'workoutModule/getWorkoutPlanWeeks',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddWorkout (workout) {
                const vm = this;

                if (vm.selectedWorkouts.length < 3) {
                    const workoutData = {
                                            workout_id: workout.id,
                                            name: workout.name,
                                            thumb: workout.thumb,
                                            period: workout.period ? workout.period : 'AM',
                                        };

                    vm.selectedWorkouts.push(workoutData);
                } else {
                    Swal.fire(Helper.swalWarningOptions('Oops!', 'Your maximum workout allowance limit reached, You can add only 3 workouts in a day.'));
                }
            },

            handleSavePlanWeekWorkout () {
                const vm        = this;
                const validate  = vm.validateDayPeriod();

                if (validate.status) {
                    const form = {
                                workout_plan_id: vm.selectedWeekDay.workout_plan_id,
                                workout_plan_week_id: vm.selectedWeekDay.workout_plan_week_id,
                                day: vm.selectedWeekDay.day,
                                workouts: vm.selectedWorkouts,
                            };

                    vm.savePlanWeekWorkout(form).then((result) => {
                        if (result) {
                            vm.closeModal();
                            vm.getWorkoutPlanWeeks({workout_plan_id: vm.selectedWeekDay.workout_plan_id});
                        }
                    });
                } else {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `You can add only one workout for ${validate.period}!`));
                }
            },

            handleSelectedWorkout () {
                const vm = this;

                vm.selectedWorkouts = vm.selectedWeekDay.workouts && vm.selectedWeekDay.workouts.length ? JSON.parse(JSON.stringify(vm.selectedWeekDay.workouts)) : [];
            },

            validateDayPeriod () {
                const vm        = this;
                const periods   = [];
                let status      = true;
                let period      = '';

                vm.selectedWorkouts.forEach((workout) => {
                    if (periods.includes(workout.period)) {
                        status = false;
                        period = workout.period == 'Mid' ? 'Afternoon' : (workout.period == 'AM' ? 'Morning' : 'Evening');
                    } else {
                        periods.push(workout.period);
                    }
                });

                return { status, period };
            },
        }
    }
</script>

<style scoped>
    .workout_list {
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 40px 45px 20px 45px;
    }


    .workout_card li {
        display: flex;
        justify-content: flex-start;
        /* align-items: flex-start; */
        gap: 5px;
        margin-bottom: 20px;
    }

    .workout_card li .wk_item {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        /* overflow: hidden; */
        position: relative;
    }

    .workout_card li .period {
        flex: 0 0 55px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
        display: flex;
        align-items: center;
    }
    .workout_card li .period .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 100%;
    }
    .workout_card li .period .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li){
        padding: 10px;
        font-size: 11px;
    }

    .workout_card li .period :deep(.multiselect-single-label) {
        padding: 0 15px 0 5px;
    }

    .workout_card li .period :deep(.multiselect-caret) {
        margin-right: 0;
    }

    .workout_card li .period :deep(.multiselect-single-label-text) {
        font-size: 11px;
    }

    .workout_card li .wk_item img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }

    .workout_card .card_details {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .workout_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .workout_card li span.act_btn {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 8px;
        background: #d3e6ff;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 2px;
        top: -7px;
        cursor: pointer;
    }
    .workout_card li span.act_btn.delete{
        background: #ffdddd;
        color: #eb1414;
    }

    .empty_area {
        border-radius: 10px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .empty_area h4 {
        font-family: 'Architects Daughter', cursive;
        font-size: 16px;
        line-height: 35px;
        font-weight: 300;
        color: #999;
        max-width: 100%;
        position: relative;
        padding-bottom: 20px;
    }
    @media(max-width: 767px){
        .workout_card li .wk_item img{
            width: 70px;
            height: 60px;
        }
        .workout_card .card_details{
            font-size: 11px;
            line-height: 17px;
        }
        .workout_card li span{
            top: 5px;
            right: 10px;
            font-size: 10px;
            line-height: 12px;
        }
    }
</style>